<template>
    <b-modal
        id="ModalAddProjectUser"
        size="md"
        title="Associer un utilisateur au projet"
        header-bg-variant="kalkin-1"
        header-text-variant="light"
        hide-footer
        @show="reset"
    >
        <div v-if="user">
            <b-form-group label="Utilisateur">
                {{ user.firstName }}
                <span class="text-uppercase">{{ user.lastName }}</span>
            </b-form-group>

            <b-form-group label="Rôle">
                <b-form-input
                    v-model="role"
                    list="role-list"
                    autocomplete="off"
                    placeholder="Selectionner ou saisir l'intitulé du rôle"
                    :state="isRoleValid"
                    required
                />
                <datalist id="role-list">
                    <option v-for="r in roleList" :key="r">{{ r }}</option>
                </datalist>
            </b-form-group>

            <b-form-group label="Niveau de droits">
                <b-form-select v-model="credentials">
                    <b-form-select-option :value="1">
                        Visibilité
                    </b-form-select-option>
                    <b-form-select-option :value="5">
                        Modification
                    </b-form-select-option>
                </b-form-select>
            </b-form-group>

            <b-button
                class="d-block mx-auto mt-3"
                variant="kalkin-2"
                :disabled="!isRoleValid"
                @click="save"
            >
                Associer
            </b-button>
        </div>
    </b-modal>
</template>
<script>
export default {
    props: {
        projectId: { type: [String, Number], required: true },
        user: { type: Object, required: false },
    },

    data: function() {
        return {
            role: "",
            roleList: [
                "Chef de Projet",
                "Commercial",
                "Gestion des données",
                "Gestion de l'application",
                "Designer",
                "Développeur",
                "Testeur",
                "Porteur de projet",
                "Client",
                "Agent d'accueil",
                "Responsable",
                "Stagiaire",
            ],
            credentials: {
                role: "",
                value: 1,
            },
        };
    },

    computed: {
        isRoleValid() {
            return this.role !== "";
        },
    },

    methods: {
        reset() {
            this.role = "";
        },

        async save() {
            await this.$store.dispatch("project/addUser", {
                projectId: this.projectId,
                userId: this.user.id,
                role: this.role,
                credentials: this.credentials,
            });
            this.$bvModal.hide("ModalAddProjectUser");
            this.$emit("saved");
        },
    },
};
</script>
