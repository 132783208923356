<template>
    <b-modal
        id="ModalCreateApplicationConfig"
        size="xl"
        title="Créer une configuration"
        header-bg-variant="kalkin-1"
        header-text-variant="light"
        hide-footer
    >
        <FormApplicationConfig
            :projectid="projectid"
            @saved="onSaved"
            class="mb-4"
        />
    </b-modal>
</template>
<script>
import FormApplicationConfig from "@/components/model/applicationconfig/FormApplicationConfig";
export default {
    components: {
        FormApplicationConfig,
    },

    props: {
        projectid: { type: [String, Number], required: true },
    },

    methods: {
        onSaved() {
            this.$bvModal.hide("ModalCreateApplicationConfig");
            this.$emit("saved");
        },
    },
};
</script>
