<template>
    <div>
        <div class="text-center m-2" v-if="!installations.length">
            <small class="text-secondary">Aucune installation</small>
        </div>
        <div
            class="k-flex-row k-flex-fill border rounded p-1 px-3 m-2"
            v-else
            v-for="install in installations"
            :key="`${configId}-${install.id}`"
        >
            <div>
                <small class="text-kalkin-1">Nom de l'installation</small>
                <div>{{ install.name }}</div>
            </div>

            <div :id="`key-${configId}-${install.id}`" class="key-block">
                <small class="text-kalkin-1">Clé d'installation</small>
                <div class="k-flex-row-2">
                    <span class="key-field user-select-all">
                        {{ install.id }}/{{ install.key }}
                    </span>
                    <b-button
                        size="xs"
                        variant="outline-secondary"
                        @click="copyKey(install)"
                    >
                        <b-icon-files />
                        Copier
                    </b-button>
                </div>
                <b-popover
                    :target="`key-${configId}-${install.id}`"
                    placement="top"
                    triggers="hover"
                    variant="dark"
                >
                    <div class="text-kalkin-1">URL de la configuration</div>
                    <small class="user-select-all">{{
                        getInstallConfigURL(install)
                    }}</small>
                </b-popover>
            </div>
        </div>
    </div>
</template>

<script>
import URLCreator from "@/tools/URLCreator";
import notifier from "@/tools/notifier";
export default {
    props: {
        projectId: [String, Number],
        configId: [String, Number],
        installations: Array,
    },

    methods: {
        getInstallConfigURL(install) {
            return URLCreator.createConfigURL(
                install.id,
                install.key,
                this.configId,
            );
        },

        copyKey({ id, key }) {
            navigator.clipboard.writeText(`${id}/${key}`);
            notifier.saved("Copié dans le presse-papier!");
        },
    },
};
</script>

<style lang="scss" scoped>
.key-field {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 2px 10px;

    font-family: monospace;
    font-size: 0.85rem;
    background-color: #efe;
}

.key-block {
    border-radius: 10px;
    padding: 0 10px;
    border: 1px solid #cccccc00;
    transition: border 0.4s;
}
.key-block:hover {
    cursor: help;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}
</style>
