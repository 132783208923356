<template>
    <div class="k-flex-col py-3 p-1 text-left">
        <div
            class="border rounded p-2"
            v-for="contact in contacts"
            :key="contact.id"
        >
            <div class="k-flex-row">
                <div>
                    <b-icon-card-heading class="text-kalkin-2" />
                    {{ contact.name }}
                </div>
                <div class="text-secondary">
                    <small>{{ contact.role }}</small>
                </div>
            </div>
            <div class="contact-details">
                <div v-if="contact.address">
                    <b-icon-mailbox /> {{ contact.address }}
                </div>
                <div v-if="contact.phone">
                    <b-icon-telephone /> {{ contact.phone }}
                </div>
                <div v-if="contact.mail">
                    <b-icon-envelope /> {{ contact.mail }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        contacts: Array,
    },

    methods: {},
};
</script>

<style lang="scss" scoped>
.contact-details {
    margin: 0 7px;
    padding: 0 7px;
    font-size: 80%;
    color: #aaa;
    border-left: 1px solid $secondary-color;
}
.contact-details svg {
    color: $primary-color;
}
</style>
