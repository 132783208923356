<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1><small>Projet</small> {{ project.name }}</h1>

            <div class="k-flex-row">
                <b-button
                    size="sm"
                    variant="outline-danger"
                    v-b-modal.ModalDanger
                >
                    <b-icon-exclamation-triangle /> Supprimer le projet
                </b-button>

                <b-button size="sm" variant="kalkin-1" @click="goMember">
                    <b-icon-person-square /> Vue membre
                </b-button>

                <b-button
                    size="sm"
                    variant="kalkin-1"
                    v-b-modal.ModalUpdateProject
                >
                    <b-icon-gear-fill /> Modifier le projet
                </b-button>
            </div>
        </div>
        <div class="k-page-body k-flex-row">
            <!-- First column -->
            <div class="k-col k-col-1">
                <div class="k-card-4">
                    <div class="header k-flex-row">
                        <span>Configurations</span>
                        <b-button
                            size="xs"
                            variant="outline-light"
                            v-b-modal.ModalCreateApplicationConfig
                        >
                            <b-icon-plus-circle class="mr-1" />
                            Nouvelle configuration
                        </b-button>
                    </div>
                    <div class="panel-content">
                        <ListApplicationConfig
                            :project-id="projectId"
                            :app-configs="appConfigs"
                            @changed="fetchApplicationConfigs"
                        />
                    </div>
                </div>
            </div>

            <!-- Second column -->
            <div class="k-col k-col-2">
                <div class="k-card-4">
                    <div class="header k-flex-row">Ressources du projet</div>
                    <div class="panel-content k-flex-col">
                        <b-button
                            size="sm"
                            variant="kalkin-2"
                            @click="goRessources('Installation')"
                        >
                            <b-icon-geo-fill /> Installations
                            <b-badge variant="light">
                                {{ project.installationCount || "0" }}
                            </b-badge>
                        </b-button>
                        <b-button
                            size="sm"
                            variant="kalkin-2"
                            @click="goRessources('Datasource')"
                        >
                            <b-icon-file-text-fill /> Sources de données
                            <b-badge variant="light">
                                {{ project.datasourceCount || "0" }}
                            </b-badge>
                        </b-button>
                        <b-button
                            size="sm"
                            variant="kalkin-2"
                            @click="goRessources('Arbo')"
                        >
                            <b-icon-bar-chart-steps /> Arborescences
                            <b-badge variant="light">
                                {{ project.arboCount || "0" }}
                            </b-badge>
                        </b-button>
                        <b-button
                            size="sm"
                            variant="kalkin-2"
                            @click="goRessources('Module')"
                        >
                            <b-icon-layout-wtf /> Modules
                            <b-badge variant="light">
                                {{ project.moduleCount || "0" }}
                            </b-badge>
                        </b-button>
                    </div>
                </div>

                <div class="k-card-4">
                    <div class="header k-flex-row">
                        Gestion des droits
                        <b-button
                            size="xs"
                            variant="outline-light"
                            v-b-modal.ModalProjectUsers
                        >
                            <b-icon-gear class="mr-1" />Gérer
                        </b-button>
                    </div>
                    <div class="panel-content">
                        <ListProjectUsers :users="users" />
                    </div>
                </div>

                <div class="k-card-4">
                    <div class="header k-flex-row">
                        Contacts
                        <b-button
                            size="xs"
                            variant="outline-light"
                            v-b-modal.ModalProjectContacts
                        >
                            <b-icon-gear class="mr-1" />Gérer
                        </b-button>
                    </div>
                    <div class="panel-content">
                        <ListProjectContacts :contacts="contacts" />
                    </div>
                </div>
            </div>
        </div>

        <ModalUpdateProject :project="project" @saved="fetchProject" />

        <ModalCreateApplicationConfig
            :projectid="projectId"
            @saved="fetchApplicationConfigs"
        />

        <ModalProjectUsers
            :project-id="projectId"
            :users="users"
            @saved="fetchProjectUsers"
        />

        <ModalProjectContacts
            :project-id="projectId"
            :contacts="contacts"
            @saved="fetchProjectContacts"
        />
        <ModalDanger
            message="Voulez-vous vraiment supprimer ce projet?"
            details="Attention, le projet ne doit avoir ni de ressources associées, ni de configurations pour pouvoir être supprimé."
            @confirmed="deleteProject"
        />
    </div>
</template>

<script>
import ListApplicationConfig from "@/components/model/applicationconfig/ListApplicationConfig";
import ListProjectUsers from "@/components/model/project/ListProjectUsers";
import ListProjectContacts from "@/components/model/project/ListProjectContacts";
import ModalUpdateProject from "@/components/model/project/ModalUpdateProject";
import ModalCreateApplicationConfig from "@/components/model/applicationconfig/ModalCreateApplicationConfig";
import ModalProjectUsers from "@/components/model/project/ModalProjectUsers";
import ModalProjectContacts from "@/components/model/project/ModalProjectContacts";
import ModalDanger from "@/components/modal/ModalDanger";

export default {
    components: {
        ListApplicationConfig,
        ListProjectUsers,
        ListProjectContacts,
        ModalUpdateProject,
        ModalCreateApplicationConfig,
        ModalProjectUsers,
        ModalProjectContacts,
        ModalDanger,
    },

    data: function() {
        return {
            projectId: this.$route.params.projectId,
            project: {},
            appConfigs: [],
            users: [],
            contacts: [],
        };
    },

    methods: {
        async fetchProject() {
            this.project = await this.$store.dispatch("project/getProject", {
                projectId: this.projectId,
            });
        },

        async fetchApplicationConfigs() {
            this.appConfigs = await this.$store.dispatch(
                "application/getApplicationConfigs",
                { projectId: this.projectId },
            );
        },

        async fetchProjectUsers() {
            this.users = await this.$store.dispatch("project/getUsers", {
                projectId: this.projectId,
            });
        },

        async fetchProjectContacts() {
            this.contacts = await this.$store.dispatch("project/getContacts", {
                projectId: this.projectId,
            });
        },

        async deleteProject() {
            await this.$store.dispatch("project/deleteProject", {
                projectId: this.projectId,
            });
            this.$router.push({ name: "AdminListProjects" });
        },

        goRessources(name) {
            this.$router.push({
                name: `AdminProjectList${name}s`,
                params: {
                    projectId: this.projectId,
                },
            });
        },

        goMember() {
            this.$router.push({
                name: `Project`,
                params: {
                    projectId: this.projectId,
                },
            });
        },
    },

    async beforeMount() {
        await this.fetchProject();
        await this.fetchProjectUsers();
        await this.fetchProjectContacts();
        await this.fetchApplicationConfigs();
    },
};
</script>

<style lang="scss" scoped>
.k-col {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
}

@media only screen and (max-width: 1449px) {
    .k-page-body {
        flex-wrap: wrap;
        height: auto;
    }
    .k-col-1 {
        height: auto;
        width: 100%;
    }
    .k-col-2 {
        height: auto;
        width: 100%;
    }
}

@media only screen and (min-width: 1450px) {
    .k-page-body {
        gap: 20px;
        flex-wrap: nowrap;
    }
    .k-col-1 {
        min-width: 70%;
    }
    .k-col-2 {
        flex-grow: 1;
        & > div {
            flex-grow: 0;
        }
    }
}
</style>
