<template>
    <div class="k-flex-col">
        <b-form-group>
            <label> <b>Nom</b> <span class="text-danger">*</span></label>
            <b-form-input v-model="name" :state="isNameValid" trim />
            <b-form-invalid-feedback :state="isNameValid">
                Veuillez saisir un nom
            </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group>
            <label>
                <b>Description</b> <span class="text-danger">*</span>
            </label>
            <b-form-textarea
                v-model="description"
                :state="isDescriptionValid"
                trim
            ></b-form-textarea>
            <b-form-invalid-feedback :state="isDescriptionValid">
                Veuillez saisir une description
            </b-form-invalid-feedback>
        </b-form-group>

        <div v-if="isEditMode">
            <b-form-group>
                <label>Collection de ressources</label>
                <div>
                    <b-button
                        v-if="assets"
                        size="sm"
                        variant="kalkin-1"
                        :to="{
                            name: 'MediaCollection',
                            params: { collectionId: assets.id },
                        }"
                        target="_blank"
                    >
                        <b-icon-plus-circle /> Ouvrir dans un nouvel onglet
                    </b-button>
                    <b-button
                        v-else
                        size="sm"
                        variant="kalkin-2"
                        @click="initAssetCollection"
                    >
                        <b-icon-lightning /> Initialiser la collection
                    </b-button>
                </div>
            </b-form-group>

            <b-button
                class="w-100"
                v-b-toggle.project-edit-advanced
                variant="kalkin-1"
                size="sm"
            >
                Édition avancée <b-icon-gear-fill />
            </b-button>
            <b-collapse id="project-edit-advanced" class="k-collapse">
                <b-form-group>
                    <label>Configuration brute</label>
                    <JSONTextArea
                        v-model="dataAsString"
                        @validate="isDataValid = $event"
                    />
                </b-form-group>
            </b-collapse>
        </div>

        <div class="text-center mt-2">
            <b-button
                v-if="!isEditMode"
                variant="kalkin-2"
                @click="createProject()"
            >
                <b-icon-folder-plus /> Créer le projet
            </b-button>
            <b-button v-else variant="kalkin-2" @click="updateProject()">
                <b-icon-check-circle-fill /> Enregistrer les modifications
            </b-button>
        </div>
    </div>
</template>
<script>
import JSONTextArea from "@/components/ui/item/JSONTextArea";
export default {
    components: {
        JSONTextArea,
    },

    props: {
        project: { type: Object, required: false },
    },

    data: function() {
        if (this.project) {
            return {
                isEditMode: true,
                name: this.project.name,
                description: this.project.description,
                data: this.project.data,
                isDataValid: true,
            };
        } else {
            return {
                isEditMode: false,
                name: null,
                description: null,
                data: null,
                isDataValid: true,
            };
        }
    },

    computed: {
        dataAsString: {
            get() {
                return JSON.stringify(this.data);
            },
            set(value) {
                this.data = JSON.parse(value);
            },
        },

        assets() {
            return this.data && this.data.medias && this.data.medias.assets;
        },

        isNameValid() {
            if (this.name === null) {
                return null;
            } else {
                return this.name !== "";
            }
        },

        isDescriptionValid() {
            if (this.description === null) {
                return null;
            } else {
                return this.description !== "";
            }
        },

        isValid() {
            return (
                this.isNameValid && this.isDescriptionValid && this.isDataValid
            );
        },
    },

    methods: {
        createProject: async function() {
            if (this.isValid) {
                await this.$store.dispatch("project/createProject", {
                    name: this.name,
                    description: this.description,
                });
                this.$router.push({ name: "AdminListProjects" });
            } else {
                this.name = this.name || "";
                this.description = this.description || "";
            }
        },

        updateProject: async function() {
            if (this.isValid) {
                await this.$store.dispatch("project/updateProject", {
                    projectId: this.project.id,
                    name: this.name,
                    description: this.description,
                    data: this.data || undefined,
                });
                this.$emit("saved");
            }
        },

        initAssetCollection: async function() {
            if (this.isValid) {
                await this.$store.dispatch("project/updateProject", {
                    projectId: this.project.id,
                    command: "initAssetCollection",
                });
                this.$emit("saved");
            }
        },
    },
};
</script>
