<template>
    <b-container fluid>
        <b-form-row class="mt-2">
            <b-col cols="12">
                <LabelledInputText
                    label="Nom"
                    placeholder="Nom de configuration"
                    :required="true"
                    v-model="localAppConfig.name"
                    :forceValidation="true"
                    @validated="isNameValid = $event"
                    @input="hasChanged = true"
                />
            </b-col>
        </b-form-row>

        <b-form-row>
            <b-col cols="12" class="text-right">
                <small class="font-italic" v-show="isEditMode">
                    <b-icon-info-circle />
                    Création le
                    {{ localAppConfig.createdAt | dateFromISO }} ~ Dernière
                    modification le
                    {{ localAppConfig.updatedAt | dateFromISO }}
                </small>
            </b-col>
        </b-form-row>

        <b-form-row class="mt-3">
            <b-col cols="12" class="justify-content-center d-flex">
                <b-button
                    class="m-auto"
                    variant="success"
                    @click="save"
                    :disabled="!isValid || !hasChanged"
                    >Enregistrer</b-button
                >
            </b-col>
        </b-form-row>
    </b-container>
</template>

<script>
import Vue from "vue";
import LabelledInputText from "@/components/ui/item/LabelledInputText";

const defaultAppConfig = {
    name: undefined,
};

export default {
    components: {
        LabelledInputText,
    },

    props: {
        projectid: { type: [String, Number], required: true },
        appconfig: { type: Object, required: false },
    },

    data: function() {
        return {
            hasChanged: false,
            isEditMode: this.appconfig != null,
            isNameValid: this.appconfig != null && this.appconfig.name,
            localAppConfig: Vue.util.extend(
                {},
                this.appconfig || defaultAppConfig,
            ),
        };
    },

    computed: {
        isValid() {
            return this.isNameValid;
        },
    },

    methods: {
        async save() {
            if (this.isEditMode) {
                await this.$store.dispatch(
                    "application/updateApplicationConfig",
                    {
                        projectId: this.projectid,
                        configId: this.localAppConfig.id,
                        name: this.localAppConfig.name,
                    },
                );
            } else {
                await this.$store.dispatch(
                    "application/createApplicationConfig",
                    {
                        projectId: this.projectid,
                        name: this.localAppConfig.name,
                    },
                );
            }
            this.$emit("saved");
            this.hasChanged = false;
        },
    },
};
</script>

<style></style>
