<template>
    <div div class="k-flex-col">
        <b-table
            :items="dataProvider"
            :fields="fields"
            :current-page="currentPage"
            :filter="filter"
            show-empty
            fixed
            empty-text="Aucun utilisateur"
            empty-filtered-text="Aucun utilisateur trouvé"
            stacked="lg"
        >
            <template #cell(action)="data">
                <div class="k-flex-row-2">
                    <b-button
                        v-if="existingUsers.includes(data.item.id)"
                        size="xs"
                        variant="outline-danger"
                        @click="$emit('remove', data.item)"
                    >
                        <b-icon-person-dash-fill /> Retirer du projet
                    </b-button>
                    <b-button
                        v-if="existingUsers.includes(data.item.id)"
                        size="xs"
                        variant="outline-kalkin-1"
                        @click="$emit('edit', data.item)"
                    >
                        <b-icon-person-dash-fill /> Modifier
                    </b-button>
                    <b-button
                        v-else
                        size="xs"
                        variant="outline-kalkin-1"
                        @click="$emit('add', data.item)"
                    >
                        <b-icon-person-plus-fill /> Associer au projet
                    </b-button>
                </div>
            </template>
            <template #table-busy>
                <div class="text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Chargement des données...</strong>
                </div>
            </template>
        </b-table>
        <div>
            <b-pagination
                class="m-auto"
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="totalRows"
                align="center"
                size="sm"
                first-number
                last-number
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        projectId: { type: [String, Number], required: true },
        users: { type: Array, required: true },
        perPage: { type: String, default: "10" },
        filter: { type: String },
    },

    data: function() {
        return {
            totalRows: 0,
            currentPage: 1,

            fields: [
                {
                    key: "firstName",
                    label: "Prénom",
                    sortable: true,
                },
                {
                    key: "lastName",
                    label: "Nom",
                    sortable: true,
                },
                {
                    key: "action",
                    label: "Action",
                },
            ],
            existingUsers: this.users.map(u => u.id),
        };
    },

    watch: {
        async filter() {
            await this.fetchTotalRows();
        },

        users() {
            this.existingUsers = this.users.map(u => u.id);
        },
    },

    methods: {
        async fetchTotalRows() {
            this.totalRows = await this.$store.dispatch("user/countFSPUsers", {
                filter: `firstName startsWith ${this.filter} or lastName startsWith ${this.filter}`,
            });
        },

        async dataProvider(ctx) {
            let users = await this.$store.dispatch("user/getFSPUsers", {
                filter: `firstName startsWith ${this.filter} or lastName startsWith ${this.filter}`,
                sortby: (ctx.sortDesc ? "-" : "") + ctx.sortBy,
                limit: this.perPage,
                offset: this.perPage * (this.currentPage - 1),
            });
            let newUsers = users.map(user => {
                const matchedUser = this.users.find(
                    newUser => newUser.id === user.id,
                );
                return matchedUser ? matchedUser : user;
            });
            return newUsers;
        },
    },

    async beforeMount() {
        await this.fetchTotalRows();
    },
};
</script>
