import config from "../config";

export default {
    createConfigURL(installId, installKey, appConfigId) {
        return (
            `${config.api}/install/${installId}/${installKey}/` +
            `app/${appConfigId}/config`
        );
    },
};
