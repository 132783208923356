<template>
    <div>
        <div class="text-center m-2" v-if="!arbos.length">
            <small class="text-secondary">Aucune arborescence</small>
        </div>
        <div
            class="k-flex-row k-flex-fill border rounded p-1 px-3 m-2"
            v-else
            v-for="arbo in arbos"
            :key="arbo.id"
        >
            <div>
                <small class="text-kalkin-1">Nom de l'arborescence</small>
                <div>{{ arbo.name }}</div>
            </div>

            <div :id="`prog-${arbo.acaId}`" class="prog-block">
                <small class="text-kalkin-1">Programmation</small>
                <template v-if="arbo.CronJob">
                    <div class="text-warning" v-if="!arbo.CronJob.enabled">
                        <b-icon-pause-circle-fill /> Désactivée
                    </div>
                    <div
                        class="text-kalkin-2"
                        v-else-if="arbo.currentArboSnapshotId"
                    >
                        <b-icon-check-circle-fill /> Activée
                    </div>
                    <div class="text-danger" v-else>
                        <b-icon-exclamation-circle-fill /> Rien à générer
                    </div>
                    <b-popover
                        :target="`prog-${arbo.acaId}`"
                        placement="top"
                        triggers="hover"
                        variant="dark"
                    >
                        <b-badge>Job #{{ arbo.CronJob.id }}</b-badge>
                        <div>{{ arbo.CronJob.timeHuman }}</div>
                        <div>
                            <b-form-checkbox
                                class="k-switch"
                                switch
                                size="sm"
                                :checked="arbo.CronJob.enabled"
                                @change="
                                    checked =>
                                        updateCronJob(arbo.acaId, checked)
                                "
                            >
                                Activer
                            </b-form-checkbox>
                        </div>
                    </b-popover>
                </template>
                <div v-else>
                    <b-button
                        size="xs"
                        variant="outline-secondary"
                        @click="createCronJob(arbo.acaId)"
                    >
                        <b-icon-clock /> Programmer la génération
                    </b-button>
                </div>
            </div>

            <div>
                <small class="text-kalkin-1">Modifier l'arborescence</small>
                <div class="k-flex-row k-flex-fill">
                    <b-button
                        size="xs"
                        variant="outline-secondary"
                        @click="goArboStructure(arbo.id)"
                    >
                        <b-icon-bar-chart-steps /> Structure
                    </b-button>
                    <b-button
                        size="xs"
                        variant="outline-secondary"
                        @click="goArboData(arbo.id)"
                    >
                        <b-icon-file-earmark-text /> Données
                    </b-button>
                    <b-button
                        size="xs"
                        class="mr-1"
                        variant="outline-secondary"
                        @click="goEditArboStyle(arbo.id)"
                    >
                        <b-icon-brush /> Style
                    </b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        projectId: [String, Number],
        configId: [String, Number],
        acas: Array,
    },

    computed: {
        arbos() {
            return this.acas.map(aca => {
                aca.acaId = aca.id;
                return Object.assign(aca, aca.Arbo);
            });
        },
    },

    methods: {
        async createCronJob(acaId) {
            await this.$store.dispatch("application/createACACronJob", {
                projectId: this.projectId,
                acaId,
            });
            this.$emit("changed");
        },

        async updateCronJob(acaId, enabled) {
            await this.$store.dispatch("application/updateACACronJob", {
                projectId: this.projectId,
                acaId,
                enabled,
            });
            this.$emit("changed");
        },

        goArboStructure(arboId) {
            this.$router.push({
                name: "AdminEditArboStructure",
                params: {
                    projectId: this.projectId,
                    configId: this.configId,
                    arboId,
                },
            });
        },

        goArboData(arboId) {
            this.$router.push({
                name: "AdminEditArboData",
                params: {
                    projectId: this.projectId,
                    configId: this.configId,
                    arboId,
                },
            });
        },

        goEditArboStyle(arboId) {
            this.$router.push({
                name: "AdminEditArboStyle",
                params: {
                    projectId: this.projectId,
                    configId: this.configId,
                    arboId,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.prog-block {
    border-radius: 10px;
    padding: 0 10px;
    border: 1px solid #cccccc00;
    transition: border 0.4s;
}
.prog-block:hover {
    cursor: help;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}
</style>
