<template>
    <div class="k-flex-col py-3 p-1 text-left">
        <div
            class="k-flex-row border rounded p-2"
            v-for="user in users"
            :key="user.id"
        >
            <div>
                <b-icon-person-fill
                    class="text-kalkin-2 pointer"
                    @click="goUserProfile(user.id)"
                />
                {{ user.firstName }}
                <span class="text-uppercase">{{ user.lastName }}</span>
                <img
                    v-if="user.credentials >= 5"
                    src="@/assets/favicon-kalkin.png"
                    class="logo"
                />
            </div>
            <div class="text-secondary">
                <small>{{ user.ProjectUser.role }}</small>
                <!-- <b-badge class="badge">
                    admin
                </b-badge> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        users: Array,
    },

    methods: {
        goUserProfile(userId) {
            this.$router.push({
                name: "AdminUser",
                params: { userId },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.logo {
    width: 14px;
    height: 14px;
    margin: 0 0 4px 5px;
}
.badge {
    font-size: 10px;
    vertical-align: top;
    background: $secondary-color;
}
</style>
