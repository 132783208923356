<template>
    <div class="p-1 py-3">
        <div v-if="!appConfigs.length">
            <i>Aucune configuration</i>
        </div>
        <template v-else v-for="config in appConfigs">
            <div class="k-panel-2" :key="config.id">
                <div class="k-flex-row">
                    <b class="text-kalkin-2">
                        {{ config.name }}
                    </b>
                    <div class="text-right">
                        <b-button
                            class="k-btn-xs"
                            size="xs"
                            variant="secondary"
                            @click="goEditAppConfig(config.id)"
                        >
                            <b-icon-tools class="mr-1" />Assistant de
                            configuration
                        </b-button>
                    </div>
                </div>
                <template v-if="!config.ApplicationBuild">
                    <div class="text-center my-3">
                        <i>Configuration vierge</i>
                    </div>
                </template>
                <template v-else>
                    <div>
                        Application :
                        <span class="text-kalkin-1">
                            {{ config.ApplicationBuild.Application.name }}
                        </span>
                        <small>
                            version {{ config.ApplicationBuild.version }}
                        </small>
                    </div>
                    <div>
                        Template :
                        <span class="text-kalkin-1">
                            {{ config.ApplicationBuild.Application.template }}
                        </span>
                        <small v-if="config.ApplicationBuild.TemplateConfig">
                            version
                            {{ config.ApplicationBuild.TemplateConfig.version }}
                        </small>
                    </div>

                    <div class="mt-3">
                        Arborescences :
                        <ListArbos
                            :project-id="projectId"
                            :config-id="config.id"
                            :acas="config.ApplicationConfigArbos"
                            @changed="$emit('changed')"
                        />
                    </div>
                    <div class="mt-3">
                        Installations :
                        <ListInstallations
                            :project-id="projectId"
                            :config-id="config.id"
                            :installations="config.Installations"
                        />
                    </div>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import ListArbos from "./ListArbos.vue";
import ListInstallations from "./ListInstallations.vue";

export default {
    components: {
        ListArbos,
        ListInstallations,
    },

    props: {
        projectId: [String, Number],
        appConfigs: Array,
    },

    methods: {
        goEditAppConfig(configId) {
            this.$router.push({
                name: "AdminApplicationConfig",
                params: {
                    projectId: this.projectId,
                    configId: configId,
                },
            });
        },
    },
};
</script>
