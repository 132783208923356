<template>
    <div div class="k-flex-col">
        <b-table
            :items="dataProvider"
            :fields="fields"
            :current-page="currentPage"
            :filter="filter"
            show-empty
            fixed
            empty-text="Aucun contact"
            empty-filtered-text="Aucun contact trouvé"
            stacked="lg"
        >
            <template #cell(action)="data">
                <b-button
                    v-if="existingContacts.includes(data.item.id)"
                    size="xs"
                    variant="outline-danger"
                    @click="$emit('remove', data.item)"
                >
                    <b-icon-person-dash-fill /> Retirer du projet
                </b-button>
                <b-button
                    v-else
                    size="xs"
                    variant="outline-kalkin-1"
                    @click="$emit('add', data.item)"
                >
                    <b-icon-person-plus-fill /> Associer au projet
                </b-button>
            </template>
            <template #table-busy>
                <div class="text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Chargement des données...</strong>
                </div>
            </template>
        </b-table>
        <div>
            <b-pagination
                class="m-auto"
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="totalRows"
                align="center"
                size="sm"
                first-number
                last-number
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        projectId: { type: [String, Number], required: true },
        contacts: { type: Array, required: true },
        perPage: { type: String, default: "10" },
        filter: { type: String },
    },

    data: function() {
        return {
            totalRows: 0,
            currentPage: 1,
            fields: [
                {
                    key: "name",
                    label: "Identité",
                    sortable: true,
                },
                {
                    key: "phone",
                    label: "Téléphone",
                    sortable: true,
                },
                {
                    key: "mail",
                    label: "Adresse email",
                    sortable: true,
                },
                {
                    key: "action",
                    label: "Action",
                },
            ],
            existingContacts: this.contacts.map(c => c.id),
        };
    },

    watch: {
        async filter() {
            await this.fetchTotalRows();
        },

        contacts() {
            this.existingContacts = this.contacts.map(c => c.id);
        },
    },

    methods: {
        async fetchTotalRows() {
            this.totalRows = await this.$store.dispatch(
                "contact/countFSPContacts",
                {
                    filter: `name substring ${this.filter} or phone substring ${this.filter} or mail substring ${this.filter}`,
                },
            );
        },

        async dataProvider(ctx) {
            return await this.$store.dispatch("contact/getFSPContacts", {
                filter: `name substring ${this.filter} or phone substring ${this.filter} or mail substring ${this.filter}`,
                sortby: (ctx.sortDesc ? "-" : "") + ctx.sortBy,
                limit: this.perPage,
                offset: this.perPage * (this.currentPage - 1),
            });
        },
    },

    async beforeMount() {
        await this.fetchTotalRows();
    },
};
</script>
