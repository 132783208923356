<template>
    <b-modal
        id="ModalUpdateProject"
        size="lg"
        title="Modifier un projet"
        header-bg-variant="kalkin-1"
        header-text-variant="light"
        hide-footer
    >
        <FormProject :project="project" @saved="onSaved" />
    </b-modal>
</template>
<script>
import FormProject from "@/components/model/project/FormProject";
export default {
    components: {
        FormProject,
    },

    props: {
        project: { type: Object, required: true },
    },

    methods: {
        onSaved() {
            this.$bvModal.hide("ModalUpdateProject");
            this.$emit("saved");
        },
    },
};
</script>
