<template>
    <b-modal
        id="ModalProjectContacts"
        size="xl"
        title="Gestion des contacts"
        header-bg-variant="kalkin-1"
        header-text-variant="light"
        hide-footer
    >
        <b-input-group>
            <template #prepend>
                <b-input-group-text><b-icon-search /></b-input-group-text>
            </template>
            <b-form-input
                placeholder="Rechercher un contact"
                v-model="searched"
                debounce="500"
            />
            <template #append v-if="searched">
                <b-button variant="secondary" @click="searched = ''">
                    Effacer
                </b-button>
            </template>
        </b-input-group>

        <TableProjectContacts
            class="mt-3 text-center"
            :project-id="projectId"
            :contacts="contacts"
            :filter="searched"
            @add="onAddContact"
            @remove="onRemoveContact"
        />
    </b-modal>
</template>
<script>
import TableProjectContacts from "@/components/model/project/TableProjectContacts";
export default {
    components: {
        TableProjectContacts,
    },

    props: {
        projectId: { type: [String, Number], required: true },
        contacts: { type: Array, required: true },
    },

    data: function() {
        return {
            searched: "",
        };
    },

    methods: {
        async onAddContact(contact) {
            await this.$store.dispatch("project/addContact", {
                projectId: this.projectId,
                contactId: contact.id,
            });
            this.$emit("saved");
        },

        async onRemoveContact(contact) {
            await this.$store.dispatch("project/removeContact", {
                projectId: this.projectId,
                contactId: contact.id,
            });
            this.$emit("saved");
        },
    },
};
</script>
