<template>
    <b-modal
        id="ModalProjectUsers"
        size="lg"
        title="Gestion des droits"
        header-bg-variant="kalkin-1"
        header-text-variant="light"
        hide-footer
    >
        <b-input-group>
            <template #prepend>
                <b-input-group-text><b-icon-search /></b-input-group-text>
            </template>
            <b-form-input
                placeholder="Rechercher un utilisateur"
                v-model="searched"
                debounce="500"
            />
            <template #append v-if="searched">
                <b-button variant="secondary" @click="searched = ''">
                    Effacer
                </b-button>
            </template>
        </b-input-group>

        <TableProjectUsers
            class="mt-3 text-center"
            :project-id="projectId"
            :users="users"
            :filter="searched"
            @add="onAddUser"
            @remove="onRemoveUser"
            @edit="onEditUser"
        />

        <ModalAddProjectUser
            :project-id="projectId"
            :user="currentUser"
            @saved="$emit('saved')"
        />
        <ModalEditProjectUser
            :project-id="projectId"
            :user="currentUser"
            @saved="$emit('saved')"
        />
    </b-modal>
</template>
<script>
import TableProjectUsers from "@/components/model/project/TableProjectUsers";
import ModalAddProjectUser from "@/components/model/project/ModalAddProjectUser";
import ModalEditProjectUser from "@/components/model/project/ModalEditProjectUser";
export default {
    components: {
        TableProjectUsers,
        ModalAddProjectUser,
        ModalEditProjectUser,
    },

    props: {
        projectId: { type: [String, Number], required: true },
        users: { type: Array, required: true },
    },

    data: function() {
        return {
            searched: "",
            currentUser: null,
        };
    },

    methods: {
        onAddUser(user) {
            this.currentUser = user;
            this.$bvModal.show("ModalAddProjectUser");
        },

        onEditUser(user) {
            this.currentUser = user;
            this.$bvModal.show("ModalEditProjectUser");
        },

        async onRemoveUser(user) {
            await this.$store.dispatch("project/removeUser", {
                projectId: this.projectId,
                userId: user.id,
            });
            this.$emit("saved");
        },

        onSaved() {
            this.$bvModal.hide("ModalProjectUsers");
            this.$emit("saved");
        },
    },
};
</script>
